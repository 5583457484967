import React from "react";
import { connect } from "react-redux";
import { Form, Input, Icon, Checkbox, Button, Modal } from "antd";
import { Link, withRouter } from "react-router-dom";
import ObjectUitil from "../../../Utilitys/Object";
import validator from "validator";
import {
  navModal,
  navModalMode,
  ACTION_SHOW_LOADING,
} from "../../../Actions/Page";
import { registerMember } from "../../../Actions/Authentication";
import {
  contents_condition,
  contents_ebook_2,
  contents_hytextsCredit,
  contents_member,
} from "../Privacy/TermOfUse";
class AccountInfo extends React.Component {
  constructor(props) {
    super(props);
    this.isLogin = this.props.isLogin;
    this.state = {
      username: "",
      password: "",
      displayname: "",
      usernameError: "",
      usernameerrormessage: "",
      passwordError: "",
      passworderrormessage: "",
      displaynameError: "",
      displaynameerrormessage: "",
      policycheckbox: false,
      isShowpolicy: false,
    };
  }
  componentDidMount() {
    // this.props.dispatch(ACTION_SHOW_LOADING(true));
    this.props.form.validateFields();
  }
  errorHandleCheck = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  fieldOnchangehandle = (e) => {
    this.props.form.validateFields();
    switch (e.target.name) {
      case "username":
        this.setState({ username: e.target.value });
        if (e.target.value !== "" && !validator.isEmail(e.target.value)) {
          this.setState({
            usernameError: "error",
            usernameerrormessage: "อีเมลไม่ถูกต้อง",
          });
        } else {
          this.setState({ usernameError: "", usernameerrormessage: "" });
        }
        break;
      case "password":
        this.setState({ password: e.target.value });
        if (
          e.target.value !== "" &&
          !ObjectUitil.isPasswordValid(e.target.value)
        ) {
          if (/\s/g.test(e.target.value)) {
            this.setState({
              passwordError: "error",
              passworderrormessage: "ห้ามเว้นวรรคในรหัสผ่าน",
            });
          } else if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            this.setState({
              passwordError: "error",
              passworderrormessage: "กรุณาใช้ตัวอักษรภาษาอังกฤษ",
            });
          } else {
            this.setState({
              passwordError: "error",
              passworderrormessage: "โปรดตั้งรหัสผ่าน 6 ตัวขึ้นไป",
            });
          }
        } else {
          this.setState({ passwordError: "", passworderrormessage: "" });
        }
        break;
      case "displayname":
        this.setState({ displayname: e.target.value });
        if (e.target.value === "") {
          this.setState({
            displaynameError: "error",
            displaynameerrormessage: "โปรดตั้งชื่อโปรไฟล์",
          });
        } else {
          this.setState({ displaynameError: "", displaynameerrormessage: "" });
        }
        break;
      case "policycheckbox":
        this.setState({ policycheckbox: e.target.checked });
        break;
      default:
        break;
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password) {
          this.props.dispatch(
            registerMember(
              {
                email: values.username,
                password: values.password,
                displayName: values.displayname,
              },
              this.props.location.pathname
            )
          );
        }
        this.props.onSubmitHandle("AccountInfo", values);
      }
    });
  };
  policyclickHandle = () => {
    this.setState({ isShowpolicy: !this.state.isShowpolicy });
    this.props.form.setFieldsValue({
      username: this.state.username,
      password: this.state.password,
      displayname: this.state.displayname,
    });
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <Form
        name="creator_accountInfo"
        className="accountInfo"
        onSubmit={this.handleSubmit}
      >
        {!this.isLogin && (
          <div style={{ marginBottom: "32px" }}>
            หากเคยสมัคร Hytexts account แล้ว สามารถเป็นนักเขียน/สำนักพิมพ์
            <br />
            โดย
            {
              <Link
                to="#"
                className="login"
                onClick={() => {
                  if (this.props.Page.screensize.width > 900) {
                    this.props.dispatch(navModal(true));
                    this.props.dispatch(
                      navModalMode({
                        loginModal: 1,
                        registerModal: 0,
                        registerEmailModal: 0,
                      })
                    );
                  } else {
                    this.props.history.push("/login");
                  }
                }}
              >
                เข้าสู่ระบบ
              </Link>
            }
            ก่อนสมัคร หลังจากที่ลงทะเบียนเป็นนักเขียน/สำนักพิมพ์แล้ว
            <br />
            สามารถอัปโหลดหนังสือเข้ามาในระบบได้ทันที
          </div>
        )}
        <h4>อีเมล</h4>
        <Form.Item
          validateStatus={this.state.usernameError ? "error" : ""}
          help={this.state.usernameerrormessage}
        >
          {getFieldDecorator("username", {
            id: "username",
            rules: [
              {
                required: true,
                message: "Please input your email or phone number",
              },
            ],
          })(
            <Input
              name="username"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="กรอกอีเมล"
              onChange={this.fieldOnchangehandle}
            />
          )}
        </Form.Item>
        {!this.isLogin && <h4>รหัสผ่าน</h4>}
        {!this.isLogin && (
          <Form.Item
            validateStatus={this.state.passwordError ? "error" : ""}
            help={this.state.passworderrormessage || ""}
          >
            {getFieldDecorator("password", {
              id: "password",
              rules: [
                { required: true, message: "Please input your password!" },
              ],
            })(
              <Input.Password
                prefix={
                  <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                type="password"
                placeholder="กรอกรหัสผ่าน 6 ตัวขึ้นไป"
                name="password"
                onChange={this.fieldOnchangehandle}
              />
            )}
          </Form.Item>
        )}
        <h4>ชื่อโปรไฟล์</h4>
        <Form.Item
          validateStatus={this.state.displaynameError ? "error" : ""}
          help={this.state.displaynameerrormessage || ""}
          className="displayname"
        >
          {getFieldDecorator("displayname", {
            id: "displayname",
            rules: [
              { required: true, message: "Please input your display name!" },
            ],
          })(
            <Input
              prefix={
                <Icon type="smile" style={{ color: "rgba(0,0,0,.25)" }} />
              }
              type="username"
              placeholder="กรอกชื่อโปรไฟล์"
              name="displayname"
              onChange={this.fieldOnchangehandle}
            />
          )}
        </Form.Item>
        <Form.Item className="policycheckbox">
          <Checkbox
            name="policycheckbox"
            onChange={this.fieldOnchangehandle}
            checked={this.state.policycheckbox}
          >
            <span>
              ยอมรับ
              <Link
                style={{ textDecoration: "underline" }}
                to="#"
                onClick={this.policyclickHandle}
              >
                ข้อตกลงและเงื่อนไขในการใช้บริการ
              </Link>
            </span>
          </Checkbox>
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="register-form-button"
          disabled={
            this.errorHandleCheck(getFieldsError()) ||
            this.state.usernameerrormessage !== "" ||
            (this.state.passworderrormessage !== "" && !this.isLogin) ||
            this.state.displaynameerrormessage !== "" ||
            !this.state.policycheckbox
          }
        >
          {this.isLogin ? `ถัดไป` : `สมัครสมาชิก`}
        </Button>
        {!this.isLogin && (
          <div
            style={{ textAlign: "center", color: "black", fontWeight: "300" }}
          >
            คุณมีบัญชีแล้ว ?{" "}
            <Link
              className="login"
              to={this.props.Page.screensize.width > 900 ? "#" : "/login"}
              onClick={() => {
                this.props.dispatch(navModal(true));
                this.props.dispatch(
                  navModalMode({
                    loginModal: 1,
                    registerModal: 0,
                    registerEmailModal: 0,
                  })
                );
              }}
            >
              เข้าสู่ระบบ
            </Link>
          </div>
        )}
        <Modal
          visible={this.state.isShowpolicy}
          footer={null}
          maskClosable={true}
          onCancel={this.policyclickHandle}
          width={this.props.Page.screensize.width < 1100 ? "95%" : 1100}
        >
          <div className="policy-content">
            <p className="title">ข้อตกลงและเงื่อนไขในการใช้บริการ</p>
            {contents_condition.map((e) => (
              <p className="content">
                {e}
                <br />
              </p>
            ))}
            <p className="title">หนังสืออิเล็กทรอนิกส์ (E-BOOK)</p>
            {contents_ebook_2.map((e) => (
              <p className="content">
                {e}
                <br />
              </p>
            ))}
            <p className="title">HYTEXTS CREDIT</p>
            {contents_hytextsCredit.map((e) => (
              <p className="content">
                {e}
                <br />
              </p>
            ))}
            <p className="title">สมาชิกทั่วไป</p>
            {contents_member.map((e, index) =>
              index === 13 ||
              index === 14 ||
              index === 15 ||
              index === 17 ||
              index === 18 ? (
                <p className="content" style={{ marginLeft: "59px" }}>
                  {e}
                  <br />
                </p>
              ) : (
                <p className="content">
                  {e}
                  <br />
                </p>
              )
            )}
          </div>
        </Modal>
      </Form>
    );
  }
}

export const policyContent = [
  `บริษัทถือว่าผู้ใช้บริการทุกท่านยอมรับข้อตกลงและเงื่อนไขการใช้บริการกับ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด หรือ www.hytexts.com ตามรายละเอียดด้านล่างนี้`,
  `1. ผลงานลิขสิทธิ์`,
  `นักเขียนตกลงว่าข้อมูลอิเล็กทรอนิกส์ หรืองานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ (E-Book) ของนักเขียนที่ส่งให้ www.hytexts.com จำหน่ายให้แก่ลูกค้านั้น เป็นข้อมูลที่สามารถเข้าถึงและนำ
  กลับมาใช้ใหม่ได้ โดยความหมายไม่เปลี่ยนแปลง และในกรณีที่ต้องมีบุคคลลงชื่อในหนังสือ ให้ถือว่าข้อมูลทางอิเล็กทรอนิกส์นั้น ได้มีการลงลายมือชื่อแล้ว และนักเขียนได้รับอนุญาตให้จัดทำ จำหน่าย โฆษณา 
  เผยแพร่ จากเจ้าของลิขสิทธิ์ที่แท้จริงถูกต้องตามกฎหมายแล้ว และเผยแพร่งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ของนักเขียนบน www.hytexts.com ได้ โดยไม่ขัดต่อข้อตกลงกับบุคคลอื่น`,
  `2. เงื่อนไขการอนุญาต`,
  `นักเขียนตกลงให้ www.hytexts.com มีอำนาจจัดทำ จำหน่าย โฆษณา งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ และการทำธุรกรรมทางพาณิชย์อิเล็กทรอนิกส์ผ่านระบบเครือข่ายอินเตอร์เน็ต
  ที่เกี่ยวกับกิจการในเชิงพาณิชย์ ด้วยการทำซ้ำงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ และนำส่งข้อมูลงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ให้แก่ลูกค้าบน www.hytexts.com เพื่อวัตถุประ
  สงค์ในการจัดจำหน่ายในรูปแบบหนังสืออิเล็กทรอนิกส์ในประเทศไทยและต่างประเทศ โดยผ่านทาง www.hytexts.com หรือ ผ่านทางแอปพลิชั่นในแพลตฟอร์มอื่นๆ ของทาง www.hytexts.com`,
  `3. การจัดทำหนังสืออิเล็กทรอนิกส์`,
  `3.1 www.hytexts.com ตกลงว่าจะจัดจำหน่ายโดยวิธีโฆษณาและเผยแพร่งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ ภายในเวลา 1 วันทำการ กรณีเป็นไฟล์ PDF และ 5-7 วันทำการ กรณีเป็นไฟล์ 
  ePub นับตั้งแต่วันที่ได้รับข้อมูลหนังสืออิเล็กทรอนิกส์ และจะไม่แก้ไขเปลี่ยนแปลงหรือละเมิดลิขสิทธิ์ของเจ้าของเดิม ยกเว้นการแก้ไขในรูปแบบการจัดวางตัวหนังสือ เพื่อความเหมาะสมในการจัดจำ
  หน่ายในรูปแบบหนังสืออิเล็กทรอนิกส์
3.2 ในกรณีที่นักเขียนส่งต้นฉบับงานวรรณกรรมให้ที่เป็นไฟล์ Microsoft Words หรือ ไฟล์อื่นๆ ให้ www.hytexts.com จัดทำหนังสืออิเล็กทรอนิกส์เป็นไฟล์ ePub เพื่อจัดจำหน่ายใน www.hytexts.com 
  ให้ถือว่าข้อมูลหนังสืออิเล็กทรอนิกส์นั้น เป็นงานของ www.hytexts.com ห้ามไม่ให้ผู้ใดจัดจำหน่าย เว้นแต่จะได้รับอนุญาตจาก www.hytexts.com

3.3 www.hytexts.com ตกลงว่าจะทำการเข้ารหัสหนังสืออิเล็กทรอนิกส์ เพื่อป้องกันการละเมิดลิขสิทธิ์ด้วยระบบของ Adobe Content Server 4 หรือเทียบเท่า เฉพาะหนังสืออิเล็กทรอนิกส์ที่ถูกตั้งราคาขาย

3.4 นักเขียนตกลงว่าจะส่งตัวอย่างงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์โดยจะต้องมีเนื้อหาไม่น้อยกว่า 3 บท หรือ 10% ของจำนวนหน้าทั้งหมดของงานวรรณกรรม ซึ่งตัวอย่างงานวรรณ
  กรรมนี้จะไม่ถูกเข้ารหัสหนังสืออิเล็กทรอนิกส์เพื่อจัดการลิขสิทธิ์ และจะถูกใช้เพื่อประชาสัมพันธ์ หรือ โฆษณางานวรรณกรรมเท่านั้น

3.5 การจัดหน้า พิสูจน์อักษร ออกแบบปก และภาพประกอบ นักเขียนต้องเป็นผู้จัดทำด้วยตนเอง`,
  `4. ข้อกำหนดเนื้อหาของงานวรรณกรรม`,
  `www.hytexts.com มีหน้าที่ตรวจรูปแบบ การจัดวางปก และข้อมูลหนังสืออิเล็กทรอนิกส์ให้เหมาะสมกับการจัดจำหน่ายเท่านั้น โดยไม่มีหน้าที่ตรวจสอบ เนื้อหา เนื้อเรื่อง ภาพประกอบ ลิขสิทธิ์ หรือส่วน
  หนึ่งส่วนใด ของหนังสืออิเล็กทรอนิกส์ที่นักเขียนนำส่งข้อมูล ดังนั้นนักเขียนตกลงว่าจะไม่นำงานวรรณกรรมที่มีเนื้อหา เนื้อเรื่อง ภาพประกอบหรือส่วนหนึ่งส่วนใดดังต่อไปนี้ให้ www.hytexts.com จัดจำหน่าย
   โฆษณาและเผยแพร่ ใน www.hytexts.com
            4.1 งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาท หรือแสดงความอาฆาตมาดร้าย พาดพิงสถาบันพระมหากษัตริย์ รัชทายาทราชวงศ์ หรือผู้สำเร็จราชการแทนพระองค์ เป็นอันขาด
            4.2 งานวรรณกรรมที่กล่าวโจมตี หรือวิพากษ์วิจารณ์ในทางเสียหายต่อศาสนา หรือคำสอนของศาสนาใดๆ ทุกศาสนา
            4.3 งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคลที่มีต่อบุคคลอื่นหรือหน่วยงานใด
            4.4 งานวรรณกรรมที่คัดลอกผลงาน หรือบทความบนเว็บไซต์ของบุคคลอื่น
            4.5 งานวรรณกรรมที่มีเนื้อหาละเมิดกฎหมาย หรือสนับสนุนกิจกรรมที่ผิดกฎหมาย
            4.6 งานวรรณกรรมที่มีเนื้อหามีภาพประกอบหรือภาพบุคคลอื่นที่มีลักษณะหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคล
            4.7 งานวรรณกรรมที่มีเนื้อหามีการโฆษณาในวัตถุประสงค์ เพื่อใช้ในการโฆษณางานวรรณกรรมเดียวกันที่จัดจำหน่ายในที่อื่นนอกเหนือจาก www.hytexts.com
            4.8 งานวรรณกรรมที่มี ตัวอักษร หน้าปก หรือ ภาพประกอบที่ละเมิดลิขสิทธิ์ของบุคคลอื่น`,
  `5. ค่าตอบแทน`,
  ` 5.1 ค่าตอบแทนการขายผ่าน www.hytexts.com
  www.hytexts.com ตกลงจัดส่งค่าตอบแทนการขายหนังสืออิเล็กทรอนิกส์ให้แก่นักเขียน ในอัตราร้อยละ 65 ของราคาขายหนังสืออิ เล็กทรอนิกส์ คูณจำนวนหนังสืออิเล็กทรอนิกส์ที่ลูกค้าชำระเงิน
เสร็จสมบูรณ์บน www.hytexts.com ทั้งหมด
 
5.2 ค่าตอบแทนการขายผ่าน Apple In-app Purchased
   5.2.1 นักเขียนเป็นผู้กำหนดราคาขายหนังสืออิเล็กทรอนิกส์เป็นสกุลเงิน US Dollar
   5.2.2 www.hytexts.com ตกลงจัดส่งค่าตอบแทนการขายหนังสืออิเล็กทรอนิกส์ให้แก่นักเขียน ในอัตราร้อยละ 49 ของราคาขายหนังสือ
            อิเล็กทรอนิกส์ คูณจำนวนหนังสืออิเล็กทรอนิกส์ที่ลูกค้าชำระเงินเสร็จสมบูรณ์ผ่านทางระบบ In-app Purchased ของบริษัท Apple Inc โดย
            อ้างอิงจากอัตราแลกเปลี่ยนสกุลเงินจากทางบริษัท Apple Inc (บริษัท Apple Inc คิดค่าธรรมเนียมระบบ In-app Purchased 30% ของราคาขายหนังสืออิเล็กทรอนิกส์)
 
5.3 การจ่ายค่าตอบแทน
   5.3.1 www.hytexts.com ตกลงจะแจ้งให้นักเขียนทราบผลการจำหน่ายหนังสืออิเล็กทรอนิกส์ทุกครั้งเมื่อมีการซื้อขายกับบุคคลภายนอกสำเร็จ โดยแจ้งผ่านทางชื่อบัญชีผู้ใช้งานของนักเขียนที่ลงทะเบียน
           ไว้ที่ www.hytexts.com
   5.3.2 นักเขียนสามารถตรวจสอบรายละเอียดและยอดขายงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ ผ่านชื่อบัญชีผู้ใช้งานของนักเขียนที่ลงทะเบียนไว้ในระบบ
   5.3.3 สำหรับการขายผ่าน Apple In-app Purchased จะมีการนำส่งเงินค่าตอบแทนภายใน ระยะเวลา 60 วันหลังจากได้ทำการส่งรายงานยอดขายที่เกิดขึ้นให้กับนักเขียน
   5.3.4 www.hytexts.com จะนำส่งเงินค่าตอบแทนทุกวันที่ 20 ของแต่ละเดือน หรือวันทำการถัดไปหากติดวันหยุด โดยเข้าบัญชีข้อมูลธนาคารที่ลงทะเบียนไว้ในระบบ
   5.3.5 นักเขียนยินยอมให้หักภาษี ณ ที่จ่าย ตามอัตราที่ระบุในประมวลรัษฎากรจากค่าตอบแทนที่จ่ายทุกครั้ง โดย www.hytexts.com จะออกหนังสือรับรองการหักภาษี ณ ที่จ่าย และรวบรวมส่ง
            มอบให้แก่นักเขียนในเดือนมกราคมของทุกปี
   5.3.6 ในการชำระค่าตอบแทน นักเขียนจะรับผิดชอบค่าธรรมเนียมในการโอนเงินผ่านธนาคาร โดยwww.hytexts.com จะหักค่าธรรมเนียมในการโอนเงินผ่านธนาคาร ที่จ่ายตามอัตราที่ธนาคารระบุ
            ไว้จากค่าตอบแทนที่จ่ายทุกครั้ง`,
  `6. สิทธิ์เจ้าของลิขสิทธิ์`,
  `ภายใต้ข้อบังคับแห่งข้อตกลงฉบับนี้ นักเขียนยังคงเป็นเจ้าของลิขสิทธิ์ในงานวรรณกรรมตามข้อตกลงนี้ทุกประการ`,
  `7. การระบุชื่อผู้สร้างสรรค์ เจ้าของลิขสิทธิ์ และข้อมูลอื่นๆ`,
  `ในการจัดทำงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ทุกครั้ง นักเขียนจะต้องระบุชื่อผู้สร้างสรรค์งานวรรณกรรม ชื่อเจ้าของลิขสิทธิ์ สำนักพิมพ์ วัน เดือน ปี ที่พิมพ์ ตราสัญลักษณ์ของ 
  www.hytexts.com และโฆษณางานครั้งแรก  วันเดือนปีที่พิมพ์ และโฆษณางานครั้งปัจจุบันเป็นอย่างน้อย โดยระบุถ้อยคำว่า “ได้รับอนุญาตจากเจ้าของลิขสิทธิ์” และ “สงวนลิขสิทธิ์ พ.ศ. .....” และ “ อนุญาต
  ให้จัดจำหน่ายใน www.hytexts.com เท่านั้น” ให้ปรากฏในส่วนที่สังเกตเห็นได้ชัดเจน`,
  `8. ขอบเขตการรับผิดชอบต่อความเสียหาย`,
  `www.hytexts.com ตกลงว่าจะพยายามถึงที่สุดที่จะป้องกันการละเมิดลิขสิทธิ์งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ โดย www.hytexts.com จะไม่รับผิดชอบต่อความเสียหายจากการละเมิด
ลิขสิทธิ์ของงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ที่เกิดจากกรณีดังต่อไปนี้
          8.1 การละเมิดลิขสิทธิ์ตัวอย่างงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ที่ถูกใช้เพื่อการประชาสัมพันธ์หรือไม่ถูกใช้ในเชิงพาณิชย์
          8.2 การทำลายการเข้ารหัสและระบบป้องกันการละเมิดลิขสิทธิ์ของงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์จากบุคคลอื่น
          8.3 การจับภาพหน้าจอแสดงผลจากคอมพิวเตอร์ส่วนบุคคล คอมพิวเตอร์แบบพกพา โทรศัพท์มือถือ และอุปกรณ์อ่านหนังสืออิเล็กทรอนิกส์ ที่แสดงผลหนังสืออิเล็กทรอนิกส์ของนักเขียน
          8.4 การคัดลอกข้อความ เนื้อหา หน้าปก และรูปภาพ หรือส่วนใดส่วนหนึ่ง ในงานวรรณกรรมรูปแบบหนังสืออิเล็กทรอนิกส์จากบุคคลอื่น
          8.5 การเจาะฐานข้อมูลหนังสืออิเล็กทรอนิกส์ของ www.hytexts.com จากบุคคลผู้ไม่หวังดี
          8.6 www.hytexts.com ขอสงวนสิทธิการรับผิดชอบต่อความเสียหายในกรณีที่ข้อมูลภายในเว็บสูญหายหรือข้อมูลผิดพลาดไม่ครบถ้วน อันเกิดจากความผิดพลาดของอุปกรณ์ของผู้ให้บริการ 
                 Web Hosting หรือจากเหตุสุดวิสัยอื่นใด แม้ www.hytexts.com มีการสำรองข้อมูลไว้`,
  `9. การต่อสู้คดี`,
  `          9.1 นักเขียนตกลงว่าจะใช้ความระมัดระวังในการนำส่งข้อมูลหนังสืออิเล็กทรอนิกส์ ในกรณีที่เกิดพิพาทหรือในการใช้สิทธิ์ทางศาลกับบุคคลที่สาม เนื่องจากข้อมูลต่างๆ ไปละเมิดลิขสิทธิ์ หมิ่นประมาท 
                 หรือมีเนื้อหา ดังที่ได้ระบุใน ข้อ (4) เป็นเหตุให้ www.hytexts.com ได้รับความเสียหาย หรือ www.hytexts.com ต้องชำระค่าเสียหายให้แก่บุคคลที่สาม นักเขียนยินยอมชำระค่าเสียหายให้แก่ 
                 www.hytexts.com ตามความเป็นจริง
                           9.2 ในกรณีที่มีบุคคลอื่นละเมิดลิขสิทธิ์ในงานวรรณกรรมภายใต้ข้อตกลงนี้ นักเขียนตกลงมอบอำนาจให้ www.hytexts.com ในการร้องทุกข์ ฟ้องร้อง และดำเนินคดี โดยจะจัดทำหนังสือมอบอำนาจให้
                 แก่ตัวแทนทันที`,
  `10. กฎหมายที่ใช้บังคับ`,
  ` ข้อตกลงฉบับนี้ให้อยู่ภายใต้บังคับแห่งกฎหมายไทย นักเขียนได้อ่านและเข้าใจข้อความในข้อตกลงโดยตลอดดีแล้ว`,
];

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
    memberInfo: state.Member.memberInfo,
  };
};

export default withRouter(
  connect(mapStateToProps)(
    Form.create({
      name: "creator_accountInfo",
      mapPropsToFields(props) {
        const values = props.values;
        return {
          username: Form.createFormField({
            value: values.username,
          }),
          displayname: Form.createFormField({
            value: values.displayname,
          }),
        };
      },
    })(AccountInfo)
  )
);
