import React, { useState, useEffect } from "react";
import "./App.scss";
import Nav from "./Nav/Nav";
import "antd/dist/antd.css";
import { Layout, message } from "antd";
import Footer from "./Footer/Footer";
import { connect } from "react-redux";
import { fetchGuestToken } from "../Actions/Authentication";
import { receiveBrowserName, receiveOSName } from "../Actions/Page";
import PropTypes from "prop-types";
import Home from "./Views/Home";
import BlankLogin from "./Views/Login/BlankLogin";
import Login from "./Views/Login/Login";
import ShoppingCart from "./Views/ShoppingCart";
import Register from "./Views/Register/Register";
import ForgetPasswordView from "./Views/ForgetPasswordView";
import RegisterForm from "./Views/Register/Register-form";
import Payment from "./Views/Payment/Payment";
import PaymentSuccess from "./Views/Payment/PaymentSuccess";
import PaymentSuccessTF from "./Views/Payment/PaymentSuccessTF";
import PaymentHistory from "./Views/Payment/PaymentHistory";
import RegisterCreator from "./Views/RegisterCreator/RegisterCreator";
import Topic from "./Views/Topics";
import Carousel from "./Carousels/Carousel";
import Book from "./Views/Book/Book";
import AuthorPublisherAll from "./Views/AuthorPublisherAll";
import SearchView from "./Views/SearchView";
import MenuView from "./Views/MenuView";
import AllCategory from "./Views/Categories/Category-all";
import Category from "./Views/Categories/Category";
import QrCodeView from "./Views/QrCodeView";
import { Switch, Route, Redirect, withRouter, Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import NotFound from "./Views/NotFound";
import { screensizeChange } from "../Actions/Page";
import { fetchShoppingCart } from "../Actions/Payment";
import authenUtility from "..//Utilitys/Authentication";
import ScrollToTop from "..//Utilitys/ScrollToTop";
import PrivateRoute from "../Components/utils/PrivateRoute";
import GuestRoute from "../Components/utils/GuestRoute";
import FAQ from "./Views/FAQ/FAQ";
import CookiePolicy from "./Views/CookiePolicy";
import Privacy from "./Views/Privacy";
import ContactUs from "./Views/ContactUs";
import { browserName, osName } from "react-device-detect";
import Cookies from "universal-cookie";
import moment from "moment";
import PDPAForm from "./Views/Privacy/PDPAForm";
import AlreadyMember from "./Views/AlreadyMember";

//
const override = css`
  display: block;
  margin: 15% auto;
`;
const cookie = new Cookies();

export const tabData = [
  { title: "ทั้งหมด", path: "/all/ebooks" },
  { title: "หมวดหมู่", path: "/all/categories" },
  { title: "โปรโมชัน", path: "/all/promotion" },
  { title: "มาใหม่", path: "/all/newrelease" },
  { title: "ขายดี", path: "/all/bestseller" },
  // { title: "แนะนำ", path: "/recommend" },
  { title: "ละครฮิต", path: "/all/ontv" },
  { title: "ฟรี", path: "/all/free" },
  // { title: "สำนักพิมพ์", path: "/publisher/all" },
  // { title: "นักเขียน", path: "/author/all" }
];

export const tabDataFooter = [
  { title: "ร้านค้า", path: "/" },
  { title: "หมวดหมู่", path: "/all/categories" },
  { title: "ตะกร้า", path: "/shoppingcart" },
  { title: "ค้นหา", path: "/search" },
  { title: "เมนู", path: "/menu" },
];

const App = (props) => {
  const [showGoToTop, setShowGoToTop] = useState(false);
  const [cookieInfoShown, setCookieInfoShown] = useState(
    cookie.get("cookieInfoShown")
  );
  const screenWidth = props.Page.screensize.width;
  const screenHeight = props.Page.screensize.height;
  const iOS_Mac = props.Page.OSName.match(/(iOS|Mac OS)/i);
  const iPhoneX_11 = screenWidth === 375 && screenHeight === 812;
  const iPadPro11_0 = screenWidth === 834 && screenHeight === 1194;
  const iPhone11_promax = screenWidth === 414 && screenHeight === 896;
  useEffect(() => {
    console.log("test");
    props.dispatch(receiveBrowserName(browserName));
    props.dispatch(receiveOSName(osName));
    props.dispatch(fetchGuestToken());
    if (props.location.pathname !== "/shoppingcart") {
      props.dispatch(fetchShoppingCart());
    }
    resize();
    window.addEventListener("resize", resize);
    message.config({
      top: "15%",
      duration: 2,
      maxCount: 1,
    });
    window.addEventListener("scroll", function (event) {
      var scroll = this.scrollY;
      if (scroll > 700) {
        setShowGoToTop(true);
      } else {
        setShowGoToTop(false);
      }
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  useEffect(() => {
    if (
      props.Page.screensize.width > 900 &&
      (props.location.pathname === "/menu" ||
        props.location.pathname === "/search" ||
        props.location.pathname === "/contactus")
    ) {
      props.history.push("/");
    }
  }, [props.Page.screensize.width]);

  const resize = () => {
    props.dispatch(
      screensizeChange({
        width: window.innerWidth,
        height: window.screen.height,
      })
    );
  };

  useEffect(() => {
    showMessage();
  }, [props]);

  useEffect(() => {
    if (
      props.location.pathname !== "/shoppingcart" &&
      !props.Payment.shoppingCart
    ) {
      props.dispatch(fetchShoppingCart());
    }
    if (!authenUtility.isLogin(props.Authentication.userToken)) {
      localStorage.setItem(
        "shoppingCart",
        JSON.stringify(props.Payment.shoppingCart)
      );
    }
  }, [props.Authentication.userToken, props.Payment.shoppingCart]);

  const showRedirect = () => {
    if (props.Page.redirectURL !== "") {
      return <Redirect to={props.Page.redirectURL} />;
    }
  };

  const gotoTop = () => {
    if (showGoToTop) {
      return (
        <button
          className="gotoTop"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          Top
        </button>
      );
    } else {
      return "";
    }
  };

  const devWaterMark = () => {
    if (process.env.REACT_APP_ENV == "production") {
      return "";
    } else if (process.env.REACT_APP_ENV == "staging") {
      return <button className="devWaterMark">Staging Mode</button>;
    } else {
      return <button className="devWaterMark">DEV Mode</button>;
    }
  };

  const cookieTab = () => {
    return (
      <div className="cookieTab">
        {props.Page.screensize.width <= 820
          ? "เว็บไซต์ Hytexts ใช้คุกกี้"
          : "เว็บไซต์ Hytexts ใช้คุกกี้ซึ่งทำให้เว็บไซต์ใช้งานง่ายขึ้น"}{" "}
        <Link to="/cookie-policy">
          {props.Page.screensize.width <= 820
            ? "เรียนรู้เพิ่มเติม"
            : "เรียนรู้เกี่ยวกับคุกกี้ของเบราว์เซอร์เพิ่มเติม"}
        </Link>{" "}
        <button
          onClick={() => {
            setCookieInfoShown(true);
            cookie.set("cookieInfoShown", true, {
              expires: moment().add(1, "y").toDate(),
              path: "/",
            });
          }}
        >
          ตกลง
        </button>
      </div>
    );
  };

  const checkuserToken = () => {
    if (props.Authentication.userToken === "") {
      return showLoading();
    } else {
      return (
        <div>
          {props.Page.screensize.width > 900 ? (
            <Nav tabData={tabData} key="Nav" {...props} />
          ) : (
            ""
          )}
          <div
            className={
              props.location.pathname.includes("/ebook/") &&
              props.Page.showFlashSale.isShow
                ? "content-container-flash-sale"
                : props.location.pathname.includes("/ebook/")
                ? "content-container-ebook"
                : props.location.pathname === "/" ||
                  tabData.some(
                    (e) =>
                      e.path === props.location.pathname &&
                      props.location.pathname !== "/all/categories"
                  ) ||
                  props.Page.screensize.width > 900
                ? "content-container-home"
                : "content-container"
            }
          >
            {showLoading()}
            {gotoTop()}
            {devWaterMark()}
            {props.location.pathname === "/" ? (
              // ||
              // // props.location.pathname === "/home" ||
              // props.location.pathname === "/:topicId"
              <Carousel screensize={props.Page.screensize.width} />
            ) : (
              ""
            )}
            <Layout.Content
              className={
                (props.location.pathname === "/" ||
                  (props.location.pathname.includes("/all/categories/") &&
                    !props.location.pathname.includes("subcategories"))) &&
                props.Page.screensize.width <= 1414
                  ? "content-noSidePadding"
                  : "content"
              }
            >
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Home} />
                  {/* <Route path="/home" component={Home} /> */}
                  <GuestRoute exact path="/login" component={Login} />
                  <GuestRoute path="/login/qrcode" component={QrCodeView} />
                  <GuestRoute
                    exact
                    path="/renewpassword"
                    component={ForgetPasswordView}
                  />
                  <Route path="/shoppingcart" component={ShoppingCart} />
                  <GuestRoute exact path="/register" component={Register} />
                  <GuestRoute
                    exact
                    path="/register/email"
                    component={RegisterForm}
                  />
                  <Route
                    exact
                    path="/register/creator"
                    component={RegisterCreator}
                  />
                  <PrivateRoute
                    exact
                    path="/payment/history"
                    component={PaymentHistory}
                  />
                  <PrivateRoute exact path="/payment" component={Payment} />
                  <PrivateRoute
                    path="/payment/success/:orderCode"
                    component={PaymentSuccess}
                  />
                  <PrivateRoute
                    path="/payment/banktransfer/:orderCode"
                    component={PaymentSuccessTF}
                  />
                  <Route exact path="/all/categories" component={AllCategory} />
                  <Route
                    exact
                    path="/all/categories/:catId"
                    component={Category}
                  />
                  <Route
                    path="/all/categories/:catId/subcategories/:subcatId"
                    component={Topic}
                  />
                  <Route path="/ebook/:bookUID" component={Book} />
                  <Route exact path="/search" component={SearchView} />
                  <Route exact path="/menu" component={MenuView} />
                  <Route exact path="/contactus" component={ContactUs} />
                  <Route
                    path="/faq/:section/:index/:subindex"
                    component={FAQ}
                  />
                  <Route path="/faq/:section/:index" component={FAQ} />
                  <Route path="/faq/:section" component={FAQ} />
                  <Route path="/relatedebooks/:bookUID" component={Topic} />
                  <Route
                    exact
                    path="/publisher/all"
                    component={AuthorPublisherAll}
                  />
                  <Route
                    exact
                    path="/author/all"
                    component={AuthorPublisherAll}
                  />
                  <Route path="/publisher/:publisherUID" component={Topic} />
                  <Route path="/keyword/:keyword" component={Topic} />
                  <Route path="/tag/:tagName" component={Topic} />
                  <Route path="/author/:authorName" component={Topic} />
                  <Route path="/alreadymember" component={AlreadyMember} />
                  <PrivateRoute exact path="/library" component={Topic} />
                  <PrivateRoute path="/library/archive" component={Topic} />
                  <PrivateRoute path="/wishlist" component={Topic} />
                  <Route path="/hytextscoin" component={Payment} />
                  <Route path="/cookie-policy" component={CookiePolicy} />
                  {/* <Route path="/forreader" component={ForReader} />
                  <Route path="/forwriter" component={ForWriter} /> */}

                  <Route
                    path="/term"
                    component={<Redirect to="/policy/privacy/term" />}
                  />
                  {/* <Route
                    path="/privacy"
                    component={<Redirect to="/policy/privacy/privacy" />}
                  /> */}
                  {/* <Route path="/test/:privacyType" component={Privacy} /> */}
                  <Route path="/privacy/:privacyType" component={Privacy} />
                  {/* <Route path="/privacy/policy" component={Privacy} />

                  <Route path="/pdpa/request" component={PDPAForm} /> */}

                  <Route path="/all/:topicId" component={Topic} />
                  <Route component={NotFound} />
                </Switch>
              </ScrollToTop>
            </Layout.Content>
          </div>
          {/* {props.location.pathname === "/newrelease" ||
          props.location.pathname === "/promotion" ||
          props.location.pathname === "/bestseller" ||
          props.location.pathname === "/recommend" ||
          props.location.pathname === "/free" ? (
            ""
          ) : ( */}
          {props.Page.screensize.width <= 900 ? (
            <>
              {cookieInfoShown ? (
                ""
              ) : (
                <Layout.Header
                  style={{
                    backgroundColor: "#f2f2f2",
                    position: "fixed",
                    zIndex: 100,
                    width: "100%",
                    height: "48px",
                    minWidth: "290px",
                    bottom:
                      iOS_Mac && (iPhoneX_11 || iPhone11_promax || iPadPro11_0)
                        ? "67px"
                        : "53px",
                    lineHeight: "40px",
                    padding:
                      props.Page.screensize.width <= 475 ? "0 0" : "0 50px",
                  }}
                >
                  {cookieTab()}
                </Layout.Header>
              )}
              <Nav
                tabData={[tabData[0], ...tabData.slice(2)]}
                tabDataFooter={tabDataFooter}
                key="Nav"
                {...props}
                isMobile={true}
              />
            </>
          ) : (
            ""
          )}
          {props.Page.screensize.width > 900 ? (
            <>
              <Footer className="footer" />
              {cookieInfoShown ? (
                ""
              ) : (
                <Layout.Header
                  style={{
                    backgroundColor: "#f2f2f2",
                    position: "fixed",
                    zIndex: 100,
                    width: "100%",
                    height: "48px",
                    minWidth: "290px",
                    bottom: "0",
                    lineHeight: "40px",
                  }}
                >
                  {cookieTab()}
                </Layout.Header>
              )}
            </>
          ) : (
            ""
          )}
          {/* )} */}
        </div>
      );
    }
  };
  const showMessage = () => {
    if (props.Message.isShow) {
      let wording;
      switch (props.Message.message.toLowerCase()) {
        case "wrong password":
          wording = "ข้อมูลผู้ใช้หรือรหัสผ่านไม่ถูกต้อง";
          break;
        case "user email or id already exist":
          wording = "อีเมลหรือเบอร์โทรศัพท์มีในระบบแล้ว";
          break;
        case "user email already exist":
          wording = "อีเมลมีในระบบแล้ว";
          break;
        case "user phone already exist":
          wording = "อีเมลหรือเบอร์โทรศัพท์มีในระบบแล้ว";
          break;
        case "you have been logout":
          wording = "คุณออกจากระบบเรียบร้อยแล้ว";
          break;
        case "no user found":
          wording = "ข้อมูลผู้ใช้หรือรหัสผ่านไม่ถูกต้อง";
          break;
        default:
          wording = props.Message.message;
          break;
      }
      if (wording !== "คุณออกจากระบบเรียบร้อยแล้ว") {
        message.error(
          <span style={{ fontFamily: "Prompt", fontSize: "16px" }}>
            {wording}
          </span>,
          3,
          () => {
            message.destroy();
          }
        );
      }
      props.Message.isShow = false;
    }
  };
  const showLoading = () => {
    if (props.Page.isLoading) {
      return (
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={50}
            color={"#123abc"}
            loading={true}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const checkLoginReDirect = () => {
    if (authenUtility.isLogin(props.Authentication.userToken)) {
      return props.history.goBack();
    } else {
      return <Route path="/login" component={BlankLogin} />;
    }
  };

  return (
    <div>
      {checkuserToken()}
      {showRedirect()}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Authentication: state.Authentication,
    Page: state.Page,
    Message: state.Message,
    Payment: state.Payment,
  };
};
App.propTypes = {
  Authentication: PropTypes.object,
  dispatch: PropTypes.func,
  Page: PropTypes.object,
  history: PropTypes.object,
  Message: PropTypes.object,
  Payment: PropTypes.object,
};
export default withRouter(connect(mapStateToProps)(App));
